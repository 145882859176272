import type { ISHISession, Person } from '@nib/types-session-api';
import { Selected, Title, Gender } from '@nib/phi-constants';
import { produce } from 'immer';
import { FamilyDetailsValues, Dependant } from '../../../../components/Forms/FamilyDetails/types';
import { ISHIPageIndex, ISHIPageList } from '../../../../constants';

export const mapFamilyDetailsToSession = (values: FamilyDetailsValues, session: ISHISession, isPageComplete: boolean): ISHISession => {
  const mappedSession = produce<ISHISession>(session, (draftSession: ISHISession) => {
    setDependantsMapping(values, draftSession);
    setPartnerMapping(values, draftSession);

    draftSession.personalDetails.hasPartner = Selected[values.hasPartner || Selected.unselected];
    draftSession.personalDetails.hasDependants = Selected[values.hasDependants || Selected.unselected];

    if (draftSession.personalDetails.hasPartner === Selected.yes) {
      draftSession.personalDetails.partner = {
        title: Title[values.partnerTitle || ''] || null,
        firstName: values.partnerFirstName,
        lastName: values.partnerLastName,
        gender: Gender[values.partnerSex || ''] || null,
        dateOfBirth: values.partnerDateOfBirth,
      };
    }

    if (isPageComplete) {
      if (draftSession.funnelProgress) {
        draftSession.funnelProgress.lastCompletedPage = ISHIPageList[ISHIPageIndex.FAMILY_DETAILS];
      }
    }
  });
  return mappedSession;
};

const setDependantsMapping = (values: FamilyDetailsValues, session: ISHISession): void => {
  if (values.hasDependants !== Selected.yes) {
    session.personalDetails.dependants = [];
  } else {
    session.personalDetails.dependants = mapDependantsToSession(values.dependants);
  }
};

const setPartnerMapping = (values: FamilyDetailsValues, session: ISHISession): void => {
  if (values.hasPartner !== Selected.yes) {
    session.personalDetails.partner = undefined;
  }
};

const mapDependantToSession = (dependant: Dependant): Person => {
  return {
    dateOfBirth: dependant.childDateOfBirth || '',
    firstName: dependant.childFirstName || '',
    gender: Gender[dependant.childSexOf || ''],
    lastName: dependant.childLastName || '',
    title: Title[dependant.childTitle || ''],
  };
};

const mapDependantsToSession = (dependants: Dependant[]): Person[] => {
  const mappedPersons: Person[] = [];

  dependants && dependants.forEach((dependant) => mappedPersons.push(mapDependantToSession(dependant)));

  return mappedPersons;
};
