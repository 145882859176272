import axios, { AxiosRequestConfig } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppsConfigURL, MOCK_CURRENT_DATE } from '../../constants';
import Cookies from 'js-cookie';

export const fetchFeatureToggles = createAsyncThunk<FeatureToggles, void>('featureToggles/fetch', async () => {
  const mockedCurrentDate = Cookies.get(MOCK_CURRENT_DATE);
  const options: AxiosRequestConfig = {};

  if (mockedCurrentDate && process.env.GATSBY_ENVIRONMENT !== 'production') {
    options.headers = {
      'x-mock-current-date': mockedCurrentDate,
    };
  }

  const features = await axios.get(`${AppsConfigURL}/v1/feature-toggles`, options);
  return features.data.data;
});
