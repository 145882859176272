import type { Nullable } from '@nib/types-session-api';
import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { PricingResponse } from './api';
import { fetchPrice } from './thunks';

export interface PricingState {
  pricingResponse: Nullable<PricingResponse>;
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  error: Nullable<SerializedError>;
}

const initialState: PricingState = {
  pricingResponse: null,
  loading: 'idle',
  error: null,
} as const;

const pricingSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Reducers for policy price
    builder.addCase(fetchPrice.pending, (state) => {
      state.loading = 'pending';
      state.error = null;
    });

    builder.addCase(fetchPrice.fulfilled, (state, action) => {
      state.pricingResponse = action.payload;
      state.loading = 'fulfilled';
    });

    builder.addCase(fetchPrice.rejected, (state, action) => {
      state.error = action.error;
      state.loading = 'rejected';
    });
  },
});

export default pricingSlice.reducer;
