import type { ISHISession, Scale } from '@nib/types-session-api';
import { ISHIJoinState } from '../..';
import * as personalDetailsSelectors from './personalDetails';

export const selectData = (state: ISHIJoinState) => state.session;

export const getSession = (state: ISHIJoinState) => state.session.session || ({} as ISHISession);
export const getSessionFetched = (state: ISHIJoinState) => state.session.fetched;
export const getSessionError = (state: ISHIJoinState) => state.session.error;
export const getPersonalDetails = (state: ISHIJoinState) => personalDetailsSelectors.selectData(state);

export const getCoverStartDate = (state: ISHIJoinState): string | null | undefined => getSession(state).financialDetails?.coverStartDate?.date;
export const getCoverEndDate = (state: ISHIJoinState): string | null | undefined => getSession(state).financialDetails?.coverEndDate;
export const getHospitalProduct = (state: ISHIJoinState): number | null => getSession(state).productSelection?.hospital?.id;
export const getScale = (state: ISHIJoinState): Scale => getSession(state).personalDetails?.scale;
export const getProductName = (state: ISHIJoinState): string => getSession(state).productSelection?.hospital?.name || '';
