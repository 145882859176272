import type { ISHISession, FunnelProgress } from '@nib/types-session-api';
import { produce } from 'immer';
import { ISHIPageIndex, ISHIPageList } from '../../../constants';
import { ISHIPage } from '../../../types/custom';
import { ISHIJoinState } from '../..';

export type CurrentPage = 'personal' | 'family' | 'payment';

export const mapActivePageToSession = (currentPage: CurrentPage, session: ISHISession): ISHISession => {
  const mappedSession = produce<ISHISession>(session, (draftSession: ISHISession) => {
    const PAGE_MAP: Record<CurrentPage, ISHIPage> = {
      personal: ISHIPageList[ISHIPageIndex.PERSONAL_DETAILS],
      family: ISHIPageList[ISHIPageIndex.FAMILY_DETAILS],
      payment: ISHIPageList[ISHIPageIndex.PAYMENT_DETAILS],
    };

    if (draftSession.funnelProgress) {
      draftSession.funnelProgress.activePage = PAGE_MAP[currentPage];
    }
  });

  return mappedSession;
};

export const selectData = (state: ISHIJoinState): FunnelProgress => {
  return state?.session?.session?.funnelProgress || ({} as FunnelProgress);
};

export const getLastCompletedPage = (state: ISHIJoinState): ISHIPage => selectData(state).lastCompletedPage || {};
