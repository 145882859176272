import type { Nullable } from '@nib/types-session-api';
import { Selected } from '@nib/phi-constants';
import { format, parseISO } from 'date-fns';
import { NIB_CONTACT_LINK } from '../constants';
import { getCurrentDate } from '../redux/session/utils';

const nibBrand = 'nib';

export const isBrowser = () => typeof window !== 'undefined';

/* BRAND / THEME  */
export const getBrand = (): string => process.env.GATSBY_BRAND || nibBrand;

export const isNibBrand = (): boolean => {
  return getBrand() === nibBrand;
};
export const FUNNEL_NAME = isNibBrand() ? 'ISHI' : getBrand().toUpperCase();

/* DATES */
export const parseDate = (dateStr: string): Date => parseISO(dateStr);
export const getDay = (dateStr: string) => format(parseDate(dateStr), 'dd');
export const getMonth = (dateStr: string) => format(parseDate(dateStr), 'MM');
export const getYear = (dateStr: string) => format(parseDate(dateStr), 'yyyy');

/* Boolean */
export const nullableBooleanToSelectedString = (value: Nullable<boolean> | undefined, defaultTo) => {
  if (value === null || value === undefined) {
    return defaultTo !== undefined ? defaultTo : Selected.unselected;
  } else {
    return value ? Selected.yes : Selected.no;
  }
};

export const getContactUsLink = (): string => {
  return NIB_CONTACT_LINK;
};

export const isValidDateFormat = (dateString) => /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/.test(dateString);

export const getItemsFromDate = (date, field, separator = '-') => {
  if (!isValidDateFormat(date)) {
    return '';
  }

  const [year = '', month = '', day = ''] = date.split(separator);
  const dateObject = { year, month, day };

  return dateObject[field];
};

export const stripDrFromTitle = (title): string => {
  if (title && title === 'Dr') {
    return '';
  }
  return title;
};

export const isCurrentDateGreaterThan = (dateString: string | undefined): boolean => {
  if (!dateString) {
    return false;
  }

  const parsedPpcDate: Date = new Date(dateString);

  if (isNaN(parsedPpcDate.getTime())) {
    return false;
  }

  return getCurrentDate() > parsedPpcDate;
};
