import type { DependantsResult, JoinSubmission, PaymentDetails } from '@nib/types-join-api';
import { captureException } from '@sentry/gatsby';
import axios, { AxiosRequestConfig } from 'axios';
import { JoinURL, MOCK_CURRENT_DATE } from '../../constants';
import Cookies from 'js-cookie';

export interface IshiJoinResponse {
  joinId?: string;
  policyId?: string;
  policyNumber?: string;
  paymentDetails?: PaymentDetails;
  customers?: DependantsResult[];
  JoinSubmission?: JoinSubmission;
}

// TODO: fix once we have a join-services-api types package
type IshiSessionWithPayment = any;

export const post = async (joinData: IshiSessionWithPayment): Promise<IshiJoinResponse> => {
  const options: AxiosRequestConfig = {};

  try {
    joinData = {
      ...joinData,
      clientTimeStamp: new Date().toISOString(),
    };

    const mockedCurrentDate = Cookies.get(MOCK_CURRENT_DATE);
    if (mockedCurrentDate && process.env.GATSBY_ENVIRONMENT !== 'production') {
      options.headers = {
        'x-mock-current-date': mockedCurrentDate,
      };
    }

    const res = await axios.post(JoinURL, joinData, options);

    return res.data;
  } catch (error) {
    captureException(error);
    throw error;
  }
};
