import reactMetricsMiddleware from '@nib/react-metrics-redux';
import { configureStore } from '@reduxjs/toolkit';
import { metrics } from '../metrics';
import rootReducer from './rootReducer';
import { sentryMiddleware, sentryReduxEnhancer } from './sentryMiddleware';

/**
 * https://redux-toolkit.js.org/api/configureStore
 */

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).prepend(sentryMiddleware.middleware).concat(reactMetricsMiddleware(metrics)),
  enhancers: [sentryReduxEnhancer],
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer));
}

export type ISHIJoinState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
