import React from 'react';
import { metrics as reactMetrics, createMetrics } from '@nib/react-metrics';
import flowright from 'lodash.flowright';
import TealiumUniversalTag from '@nib/tealium-universal-tag';
import trackPage from '@nib/track-page';

export const getConfig = (): MetricsConfig => {
  let config = {} as any;
  if (typeof window === 'undefined') {
    config.tealium = {};
    config.tealium.account = process.env.GATSBY_TEALIUM_ACCOUNT || '';
    config.tealium.profile = process.env.GATSBY_TEALIUM_PROFILE || '';
    config.tealium.environment = process.env.GATSBY_TEALIUM_ENVIRONMENT || '';
  } else {
    config = window.__CONFIG__;
  }
  return config;
};

const nibMetricsConfig = {
  vendors: [
    {
      name: 'Tealium Universal Tag',
      api: new TealiumUniversalTag({
        ...getConfig().tealium,
        manualPageView: true,
      }),
    },
  ],
};

const nibMetricsOptions = {
  autoTrackPageView: false,
};

const mapMetricsToProps = (options: TealiumOptions) => ({
  channel: options.channel || 'retail', // Channel in which the transaction is taking place, eg. "Retail", "Corporate" (GA CD9)
  site_name: options.siteName || 'nib.com.au', // Site Name or Identifier
  site_section: options.siteSection || 'ishi_join', // The high-level sections of your site eg. Home, The Check Up, Health Insurance, Content, My Account etc.
  page_type: options.pageType || 'ishi_content', // Type of page eg. home, content
  page_name: options.pageName || 'ishi_content_main', // Tealium variable to identify the page name // project-level default, to be overwritten on each page
});

/**
 * HOC to wrap each page for tealium/analytics tracking
 */
const enhance = (options: TealiumOptions): React.FC => flowright(reactMetrics(nibMetricsConfig, nibMetricsOptions), trackPage(mapMetricsToProps(options)));

export const metrics = createMetrics(nibMetricsConfig);

export default enhance;

export interface TealiumOptions {
  channel?: string;
  siteName?: string;
  siteSection?: string;
  pageType?: string;
  pageName?: string;
}

export type MetricsConfig = {
  tealium?: TealiumVendorConfig;
};

type TealiumVendorConfig = {
  account: string;
  profile: string;
  environment: string;
};
