import axios, { AxiosRequestConfig } from 'axios';
import { AppsConfigURL, MOCK_CURRENT_DATE } from '../../constants';
import { captureException } from '@sentry/gatsby';
import Cookies from 'js-cookie';

export interface FetchPpcResponse {
  [key: string]: string | number | boolean;
  ppcDate: string;
}

export const fetchPpcConfig = async (): Promise<FetchPpcResponse> => {
  const mockedCurrentDate = Cookies.get(MOCK_CURRENT_DATE);
  const options: AxiosRequestConfig = {};

  if (mockedCurrentDate && process.env.GATSBY_ENVIRONMENT !== 'production') {
    options.headers = {
      'x-mock-current-date': mockedCurrentDate,
    };
  }

  try {
    const response = await axios.get<{ data: FetchPpcResponse }>(`${AppsConfigURL}/v1/ppc/ishi`, options);
    return response.data.data;
  } catch (error) {
    captureException(error);
    throw error;
  }
};
