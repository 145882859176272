import type { ISHISession, Scale } from '@nib/types-session-api';
import axios from 'axios';
import { IshiSessionInitURL, SessionURLV2 } from '../../constants';

export interface SessionInitialParams {
  id?: string;
  hospitalProductId: number;
  scale: Scale;
  coverStartDate: string;
  coverEndDate: string;
}

export const init = (params: SessionInitialParams) => {
  return axios.post<ISHISession>(IshiSessionInitURL, {}, { params });
};

export const save = (session: ISHISession) => {
  return axios.put<ISHISession>(SessionURLV2, session);
};
