import { produce } from 'immer';
import { PaymentMethod } from '@nib/phi-constants';
import { PaymentDetailsValues } from '../../../components/Forms/PaymentDetails/types';
import { updateDependantsMapping, updatePartnerMapping } from '../../session/utils';

// TODO: fix once we have a join-services-api types package
type IshiSessionWithPayment = any;
type BraintreeDetails = any;

export const mapPaymentValuesToJoin = async (values: PaymentDetailsValues, joinSession: IshiSessionWithPayment): Promise<IshiSessionWithPayment> => {
  const mappedSession = produce(joinSession, async (draftJoinSession) => {
    let braintreeDetails: BraintreeDetails | undefined;
    if (values.nonce) {
      braintreeDetails = {
        nonce: values.nonce,
      };
    }

    draftJoinSession.financialDetails.braintreeDetails = braintreeDetails;
    draftJoinSession.financialDetails.creditCardDetails = undefined;
    draftJoinSession.financialDetails.paymentMethod = PaymentMethod.Braintree;
    draftJoinSession.financialDetails.bankAccountDetails = undefined;

    updateDependantsMapping(draftJoinSession);
    updatePartnerMapping(draftJoinSession);

    return draftJoinSession;
  });

  return mappedSession;
};
