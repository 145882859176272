import axios, { AxiosError } from 'axios';
import { captureException } from '@sentry/gatsby';

/** https://github.com/axios/axios#interceptors */

const configInterceptors = () => {
  axios.interceptors.request.use(
    (config) => config,
    (error: AxiosError) => {
      error.name = `Axios Request Error: ${error.name}`;
      captureException(error);
      return Promise.reject(error);
    }
  );

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.isAxiosError) {
        error.message = `${error.name}: ${error.message}`;
        error.name = `Axios Response Error: ${error.config.url}`;
        const tags = {
          status: error.response?.status,
          method: error.config.method,
        };
        captureException(error, { tags });
      } else {
        error.name = `Non AxiosError: ${error.name}`;
        captureException(error);
      }

      return Promise.reject(error);
    }
  );
};

export const configAxios = () => {
  configInterceptors();
};

export const setCorrelationIdHeader = (id: string) => {
  axios.defaults.headers.common['Nib-CorrelationId'] = id;
};
