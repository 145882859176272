import { SerializedError, createSlice } from '@reduxjs/toolkit';
import { fetchPpcConfig } from './thunks';
import type { Nullable } from '@nib/types-session-api';
import { FetchPpcResponse } from './api';

export interface PpcConfigState {
  ppcConfigResponse: Nullable<FetchPpcResponse>;
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
  error: Nullable<SerializedError>;
}

const initialState: PpcConfigState = {
  ppcConfigResponse: null,
  loading: 'idle',
  error: null,
} as const;

const ppcConfigSlice = createSlice({
  name: 'ppc',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPpcConfig.pending, (state) => {
      state.loading = 'pending';
      state.error = null;
    });

    builder.addCase(fetchPpcConfig.fulfilled, (state, action) => {
      state.ppcConfigResponse = action.payload;
      state.loading = 'fulfilled';
    });

    builder.addCase(fetchPpcConfig.rejected, (state, action) => {
      state.error = action.error;
      state.loading = 'rejected';
    });
  },
});

export default ppcConfigSlice.reducer;
