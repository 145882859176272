import type { PersonalDetails, PolicyHolder } from '@nib/types-session-api';
import { ISHIJoinState } from '../..';
import get from 'lodash.get';

export const selectData = (state: ISHIJoinState): PersonalDetails => get(state, 'session.session.personalDetails', {} as PersonalDetails);

export const getScale = (state: ISHIJoinState) => selectData(state).scale;
export const getPolicyHolder = (state: ISHIJoinState) => selectData(state).policyHolder || ({} as PolicyHolder);
export const getPolicyHolderDob = (state: ISHIJoinState) => getPolicyHolder(state).dateOfBirth;
export const getPolicyHolderGender = (state: ISHIJoinState) => getPolicyHolder(state).gender;
export const getPolicyHolderFirstName = (state: ISHIJoinState) => getPolicyHolder(state).firstName;
export const getPolicyHolderLastName = (state: ISHIJoinState) => getPolicyHolder(state).lastName;
export const getPolicyHolderEmail = (state: ISHIJoinState) => getPolicyHolder(state).email;
export const getPolicyHolderTitle = (state: ISHIJoinState) => getPolicyHolder(state).title;
export const getCitizenshipCountry = (state: ISHIJoinState) => selectData(state).citizenshipCountry;
export const getCurrentlyInAustralia = (state: ISHIJoinState) => selectData(state).isTheApplicantCurrentlyInAustralia;
