import { combineReducers } from '@reduxjs/toolkit';
import session from './session/slice';
import pricing from './pricing/slice';
import join from './join/slice';
import featureToggles from './featureToggles/slice';
import ppc from './ppcConfig/slice';
import stepper from './stepper/slice';

/**
 * https://redux-toolkit.js.org/api/other-exports#combinereducers
 */

const rootReducer = combineReducers({
  session,
  pricing,
  join,
  featureToggles,
  ppc,
  stepper,
});

export default rootReducer;
