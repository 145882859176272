import type { Scale } from '@nib/types-session-api';
import axios from 'axios';
import { MOCK_CURRENT_DATE, PricingURL } from '../../constants';
import Cookies from 'js-cookie';

// TODO: pricing-api-lambda types are not exported. Fix as part of typing package work
// and then remove this
export interface PricingParams {
  startDate: string;
  endDate: string;
  scale: Scale;
  hospitalProduct: number;
  effectiveDate?: string;
  rate: string;
}

export interface PricingResponse {
  paymentByFrequency: number;
  paymentTotal: number;
  tenureRating: number;
}
/**
 * Fetch the Pricing for the given product and factors
 */
export const getPrice = async (params: PricingParams): Promise<PricingResponse> => {
  const mockedCurrentDate = Cookies.get(MOCK_CURRENT_DATE);

  if (mockedCurrentDate && process.env.GATSBY_ENVIRONMENT !== 'production') {
    params.effectiveDate = mockedCurrentDate;
  }

  const response = await axios.get<{ data: PricingResponse }>(PricingURL, { params });
  return response.data.data;
};
