import { ISHIFunnelPage } from './types/custom';

const apiBasePath = process.env.GATSBY_JOIN_SERVICES_API_PREFIX || 'https://join-services-api.kaos.internal.nibit.com.au';
const pricingApiBasePath = process.env.GATSBY_PRICING_API_URL || 'https://api-gateway.nib-cf-test.com/pricing-api-lambda';
export const AppsConfigURL = process.env.GATSBY_FEATURE_TOGGLE_URL || 'https://api-gateway.nib-cf-test.com/apps-config-lambda';
export const gatsbyPathPrefix = process.env.GATSBY_PATH_PREFIX || '';

// URLs
export const IshiSessionInitURL = `${apiBasePath}/v1/ishi/session/init`;
export const SessionURLV2 = `${apiBasePath}/v2/session/ishi`;
export const PricingURL = `${pricingApiBasePath}/v1/international-student`;
export const JoinURL = `${apiBasePath}/v1/join/ishi`;

// Cookies
export const PRICING_COOKIE = 'Oshc_PricingCalc';
export const SESSION_COOKIE = 'Oshc_Quote';
export const SESSION_AGENT_NAME = 'Oshc_AgentName';
export const SESSION_COOKIE_VERSION = '2';
export const HOSPITAL_PRODUCT_ID = 34;
export const MOCK_CURRENT_DATE = 'mock-current-date';

// Braintree
export const BraintreeClientTokenApiUrl = process.env.GATSBY_BRAINTREE_CLIENT_TOKEN_API_URL || '';
export const BraintreeClientTokenApiKey = process.env.GATSBY_BRAINTREE_CLIENT_TOKEN_API_KEY || '';

// Funnel progress
export const NIB_CONTACT_LINK = '/contact-us';

export const ISHIPageList: ISHIFunnelPage[] = [
  {
    name: 'Landing',
    title: 'Health Cover',
    mobileTitle: 'Cover',
    link: '/',
    stepOrder: 0,
  },
  {
    name: 'PersonalDetails',
    title: 'Your Details',
    mobileTitle: 'You',
    link: '/join/personal-details',
    stepOrder: 1,
  },
  {
    name: 'FamilyDetails',
    title: 'Family Details',
    mobileTitle: 'Family',
    link: '/join/family-details',
    stepOrder: 2,
  },
  {
    name: 'PartnerDetails',
    title: 'Partner Details',
    mobileTitle: 'Partner',
    link: '/join/family-details',
    stepOrder: 2,
  },
  {
    name: 'PaymentDetails',
    title: 'Payment Details',
    mobileTitle: 'Pay',
    link: '/join/payment-details',
    stepOrder: 3,
  },
  {
    name: 'Complete',
    title: 'Complete',
    mobileTitle: 'Complete',
    link: '/join/complete',
    stepOrder: 4,
  },
];

export enum ISHIPageIndex {
  LANDING = 0,
  PERSONAL_DETAILS = 1,
  FAMILY_DETAILS = 2,
  PARTNER_DETAILS = 3,
  PAYMENT_DETAILS = 4,
  COMPLETE = 5,
}

export const isDev = () => process.env.GATSBY_ENVIRONMENT === 'local';

export const hspConfigurationUrl = process.env.GATSBY_HSP_CONFIGURATION_URL || 'https://api-gateway.nib-cf-test.com/nib-hsp-configuration-api/v1/config?app=ishiJoinWeb&version=1.0.0&brand=nib';
