import type { GovernmentDetails, ISHISession, ISHIVisaDetails, JoinProgress, PreviousFundDetails } from '@nib/types-session-api';
import { Selected } from '@nib/phi-constants';
import { produce } from 'immer';
import { PersonalDetailsValues } from '../../../../components/Forms/PersonalDetails/types';
import { ISHIPageIndex, ISHIPageList } from '../../../../constants';
import { updateDependantsMapping, updatePartnerMapping } from '../../utils';

export const mapPersonalDetailsToSession = (values: PersonalDetailsValues, session: ISHISession, isPageComplete: boolean): ISHISession => {
  const mappedSession = produce<ISHISession>(session, (draftSession: ISHISession) => {
    draftSession.personalDetails.policyHolder.title = values.title;
    draftSession.personalDetails.policyHolder.gender = values.gender;
    draftSession.personalDetails.policyHolder.firstName = values.firstName;
    draftSession.personalDetails.policyHolder.lastName = values.lastName;
    draftSession.personalDetails.policyHolder.dateOfBirth = values.dateOfBirth;
    draftSession.personalDetails.policyHolder.email = values.email;
    draftSession.personalDetails.citizenshipCountry = values.countryOfCitizenship;
    draftSession.personalDetails.isTheApplicantCurrentlyInAustralia = values.currentlyInAustralia === null ? undefined : values.currentlyInAustralia === Selected.yes;

    if (draftSession.visaDetails) {
      draftSession.visaDetails.primaryApplicant = values.primaryApplicant;
      draftSession.visaDetails.passportNumber = values.passportNumber;
    } else {
      draftSession.visaDetails = {
        primaryApplicant: values.primaryApplicant,
        passportNumber: values.passportNumber,
      } as ISHIVisaDetails;
    }

    updateDependantsMapping(draftSession);
    updatePartnerMapping(draftSession);

    if (draftSession.joinProgress) {
      draftSession.joinProgress.hasAgreedToNibFundRules = values.fundRulesAndprivacyPolicyAgreement ?? false;
      draftSession.joinProgress.hasAgreedToVevoCheck = values.vevoTermsAgreement ?? false;
    } else {
      draftSession.joinProgress = {
        hasAgreedToNibFundRules: values.fundRulesAndprivacyPolicyAgreement ?? false,
        hasAgreedToVevoCheck: values.vevoTermsAgreement ?? false,
      } as JoinProgress;
    }

    const policyHolderPreviousFundDetails = {
      previouslyHadHealthInsurance: values.transferringFunds === null ? null : values.transferringFunds === Selected.yes,
      ...(values.transferringFunds === Selected.yes && {
        code: values['transferringFunds--currentProvider'],
        policyNumber: values['transferringFunds--memberNumber'],
      }),
    } as PreviousFundDetails;

    draftSession.governmentDetails = {
      ...(draftSession.governmentDetails ?? ({} as GovernmentDetails)),
      policyHolderPreviousFundDetails,
    };

    if (isPageComplete) {
      if (draftSession.funnelProgress) {
        draftSession.funnelProgress.lastCompletedPage = ISHIPageList[ISHIPageIndex.PERSONAL_DETAILS];
      }
    }
  });

  return mappedSession;
};
