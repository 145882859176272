import React from 'react';
import { GatsbyBrowser } from 'gatsby';
import { Provider } from 'react-redux';
import store from './src/redux';

const WrapWithProvider: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return <Provider store={store}>{element as any}</Provider>;
};

export default WrapWithProvider;
