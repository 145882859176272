import { Scale } from '@nib/phi-constants';
import type { ISHISession } from '@nib/types-session-api';
import { PricingCalcCookie } from '../../services/cookies';
import { SessionInitialParams } from './api';
import Cookies from 'js-cookie';
import { MOCK_CURRENT_DATE } from '../../constants';

export const parseInitialParams = (pricingCookieParams: PricingCalcCookie | Record<string, never>, queryParams = {}, id): SessionInitialParams => {
  const { coverStartDate, coverEndDate, scale, hospitalProductId } = pricingCookieParams as PricingCalcCookie;

  return {
    coverStartDate,
    coverEndDate,
    scale,
    hospitalProductId,
    ...queryParams,
    id,
  };
};

export const updateDependantsMapping = (session: ISHISession): void => {
  const scale = session.personalDetails.scale;
  if (scale === Scale.Single || scale === Scale.Couple) {
    session.personalDetails.dependants = [];
  }
};

export const updatePartnerMapping = (session: ISHISession): void => {
  const scale = session.personalDetails.scale;
  if (scale === Scale.Single || scale === Scale.SingleParentFamily) {
    session.personalDetails.partner = undefined;
  }
};

export const getCurrentDate = (): Date => {
  let mockedCurrentDate;
  if (process.env.GATSBY_ENVIRONMENT !== 'production') {
    mockedCurrentDate = Cookies.get(MOCK_CURRENT_DATE);
  }
  return mockedCurrentDate ? new Date(mockedCurrentDate) : new Date();
};
