import { createListenerMiddleware, isAnyOf, isRejected } from '@reduxjs/toolkit';
import { captureMessage, createReduxEnhancer } from '@sentry/gatsby';
import { saveSession } from './session/thunks';

/**
 * Sentry enhancer:
 * Connects Sentry to Redux so Sentry events carry more details about the store, including actions and a state snapshot.
 * https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
 */
export const sentryReduxEnhancer = createReduxEnhancer({});

/**
 * Sentry Middleware:
 * Listens to thunk rejections to send messages to Sentry.
 * https://redux-toolkit.js.org/api/createListenerMiddleware
 */
export const sentryMiddleware = createListenerMiddleware();

/**
 * List of rejection actions we don't want to listen to.
 * 'saveSession.rejected', for example, is reused by several thunks so it can be suppressed and we'll listen to the wrapping thunks instead.
 */
const actionDenyList: [typeof saveSession.rejected] = [saveSession.rejected];

sentryMiddleware.startListening({
  predicate: (action) => !isAnyOf(...actionDenyList)(action) && isRejected(action),
  effect: (action, {}) => {
    captureMessage(action.type, { level: 'error' });
  },
});
