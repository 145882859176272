import { Scale } from '@nib/phi-constants';
import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { getProgressSteps, ProgressStep, ProgressStepsArguments, transformProgressStepsForState } from '../../components/Stepper/progressSteps';

export interface StepperState {
  steps: ProgressStep[];
  currentStep: number;
}
export const initialState: StepperState = {
  steps: getProgressSteps(),
  currentStep: 0,
};

const stepperSlice: Slice<StepperState> = createSlice({
  name: 'stepper',
  initialState,
  reducers: {
    setStepsForFamilyScale: (state, action: PayloadAction<ProgressStepsArguments>) => {
      state.steps = transformProgressStepsForState(getProgressSteps(Scale.Family), action.payload);
    },
    setStepsForCoupleScale: (state, action: PayloadAction<ProgressStepsArguments>) => {
      state.steps = transformProgressStepsForState(getProgressSteps(Scale.Couple), action.payload);
    },
    setStepsForSingleScale: (state, action: PayloadAction<ProgressStepsArguments>) => {
      state.steps = transformProgressStepsForState(getProgressSteps(Scale.Single), action.payload);
    },
    setCurrentIndex: (state, action: PayloadAction<ProgressStepsArguments>) => {
      const { currentPage } = action.payload;

      for (let i = 0; i < state.steps.length; i++) {
        if (state.steps[i].title === currentPage.title) {
          state.currentStep = i;
          break;
        }
      }
    },
  },
});

export const { fetchCurrentStep, setStepsForSingleScale, setStepsForCoupleScale, setStepsForFamilyScale, setCurrentIndex } = stepperSlice.actions;

export default stepperSlice.reducer;
