import wrapWithProvider from './wrap-with-provider';
import { GatsbyBrowser } from 'gatsby';
import { configAxios } from './src/services/axiosConfig';
import './src/styles/global.css';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = wrapWithProvider;

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  configAxios();
};
