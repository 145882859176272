import * as api from './api';
import { getCookieSessionId } from '../../services/cookies';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSession } from '../session/selectors';
import { ISHIJoinState } from '..';
import { mapPaymentValuesToJoin } from './mappings/paymentValuesJoin';
import { PaymentDetailsValues } from '../../components/Forms/PaymentDetails/types';
import { IshiJoinResponse } from './api';
import { captureMessage } from '@sentry/gatsby';

// TODO: fix once we have a join-services-api types package
type IshiSessionWithPayment = any;

export const submitJoin = createAsyncThunk<IshiJoinResponse, PaymentDetailsValues, { state: ISHIJoinState }>('join', async (values, { getState }) => {
  const session = getSession(getState());
  const sessionId = getCookieSessionId();

  if (sessionId && session) {
    // This thunk has a prerequisite on the braintree payment workflow to populate the session with
    // the braintree nonce.
    const joinSession = session as IshiSessionWithPayment;
    const mappedJoin = await mapPaymentValuesToJoin(values, joinSession);

    const result = await api.post(mappedJoin);

    if (result.joinId) {
      return result;
    }
  }

  const message = 'Error submitting join -- Mapping, store or cookie error';
  console.error(message);
  captureMessage(message);
  throw new Error(message);
});
